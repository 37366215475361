import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import Heading from '@atoms/Heading'

const FeatureIconItem = ({ headFirstLine, headSecondLine, lead, icon }) => {
  return (
    <div className='w-full md:w-1/2 lg:w-1/4 px-4'>
      <div className='flex flex-col items-center justify-center text-center xl:px-4'>
        <div className='w-24 h-24 md:w-32 md:h-32 p-0.5'>
          <GatsbyImage
            image={icon}
            width={140}
            height={140}
            quality={100}
            alt={`${headFirstLine} ${headSecondLine}`}
          />
        </div>
        <Heading variant='h3' component='h5' classes="mt-5 mb-4">
          {headFirstLine}
          <br />
          {headSecondLine}
        </Heading>
        <p className='text-xs max-w-xs'>{lead}</p>
      </div>
    </div>
  )
}

FeatureIconItem.propTypes = {
  headFirstLine: PropTypes.string,
  headSecondLine: PropTypes.string,
  lead: PropTypes.string
}

FeatureIconItem.defaultProps = {
  headFirstLine: ``,
  headSecondLine: ``,
  lead: ``
}

export default FeatureIconItem
