import React, { useEffect, useState } from 'react'
import Lottie from 'react-lottie-player'
import { getImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import VisibilitySensor from 'react-visibility-sensor'
import SectionTail from '@svg/features-sections-tail.svg'
import SectionTail2 from '@svg/section-tail2.svg'
import QuoteSection from '@organisms/QuoteSection'
import CallToActionSection from '@organisms/CallToActionSection'
import HomeHero from '@organisms/HomeHero'
import FeatureSection from '@organisms/FeatureSection'
import SectionHeader from '@molecules/SectionHeader'
import FeatureIconItem from '@molecules/FeatureIconItem'
import EarlyAccessForm from '@molecules/EarlyAccessForm'
import QuoteTailTop from '@svg/quote-tail-top.svg'
import QuoteTail from '@svg/tail-quote.svg'
import Slider from 'react-slick'
import LazyLoad from 'react-lazyload'
import 'slick-carousel/slick/slick.css'
import SectionTailFlipped from '@svg/section-tail-flipped.svg'

const IndexPage = ({ data: { strapiShopperPage } }) => {
  const [animationShopper01, setAnimationShopper01] = useState()
  const [animationShopper02, setAnimationShopper02] = useState()
  const [animationShopper03, setAnimationShopper03] = useState()
  const [animationShopper04, setAnimationShopper04] = useState()

  useEffect(() => {
    import('../animations/shopper-1.json').then(setAnimationShopper01)
    import('../animations/shopper-2.json').then(setAnimationShopper02)
    import('../animations/shopper-3.json').then(setAnimationShopper03)
    import('../animations/shopper-4.json').then(setAnimationShopper04)
  }, []);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    pauseOnDotsHover: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  return (
    <Layout headerBg='bg-secondary' buttonLabel='For Merchants' buttonUrl='/for-merchants'>
      <Seo title='Tenko App | Shopper' />
      <HomeHero head={strapiShopperPage.heroHead} headSecondLine={strapiShopperPage.heroHeadSecondLine} lead={strapiShopperPage.heroLead} />
      <div className='relative max-w-screen-2xl mx-auto'>
        <SectionTailFlipped className='hidden lg:block absolute top-1/2 transform -translate-y-1/2 features-sections-tail' />
        <FeatureSection
          index={0}
          head={strapiShopperPage.featuresSection[0].head}
          headSecondLine={strapiShopperPage.featuresSection[0].headSecondLine}
          lead={strapiShopperPage.featuresSection[0].lead}
        >
          <div className='aspect-w-1 aspect-h-1'>
            <LazyLoad offset={100}>
              {(!animationShopper01) ? (
                <div></div>
              ) : (
                <VisibilitySensor>
                  {({ isVisible }) =>
                    <Lottie speed={0.85} play={isVisible ? true : false} animationData={animationShopper01} />
                  }
                </VisibilitySensor>
              )
              }
            </LazyLoad>
          </div>
        </FeatureSection>

        <FeatureSection
          index={1}
          head={strapiShopperPage.featuresSection[1].head}
          headSecondLine={strapiShopperPage.featuresSection[1].headSecondLine}
          lead={strapiShopperPage.featuresSection[1].lead}
        >
          <div className='aspect-w-1 aspect-h-1'>
            <LazyLoad offset={100}>
              {(!animationShopper02) ? (
                <div></div>
              ) : (
                <VisibilitySensor>
                  {({ isVisible }) =>
                    <Lottie speed={0.85} play={isVisible ? true : false} animationData={animationShopper02} />
                  }
                </VisibilitySensor>
              )
              }
            </LazyLoad>
          </div>
        </FeatureSection>

        <FeatureSection
          index={2}
          head={strapiShopperPage.featuresSection[2].head}
          headSecondLine={strapiShopperPage.featuresSection[2].headSecondLine}
          lead={strapiShopperPage.featuresSection[2].lead}
        >
          <div className='aspect-w-1 aspect-h-1'>
            <LazyLoad offset={100}>
              {(!animationShopper03) ? (
                <div></div>
              ) : (
                <VisibilitySensor>
                  {({ isVisible }) =>
                    <Lottie speed={0.9} play={isVisible ? true : false} animationData={animationShopper03} />
                  }
                </VisibilitySensor>
              )
              }
            </LazyLoad>
          </div>
        </FeatureSection>

        <FeatureSection
          index={3}
          head={strapiShopperPage.featuresSection[3].head}
          headSecondLine={strapiShopperPage.featuresSection[3].headSecondLine}
          lead={strapiShopperPage.featuresSection[3].lead}
        >
          <div className='aspect-w-1 aspect-h-1'>
            <LazyLoad offset={100}>
              {(!animationShopper04) ? (
                <div></div>
              ) : (
                <VisibilitySensor>
                  {({ isVisible }) =>
                    <Lottie speed={0.7} play={isVisible ? true : false} animationData={animationShopper04} />
                  }
                </VisibilitySensor>
              )
              }
            </LazyLoad>
          </div>
        </FeatureSection>

      </div>

      <section className='py-12 bg-neutral-100 lg:bg-white pt-20 md:pt-48 md:pb-12 xl:px-24 relative'>
        <div className='lg:relative max-w-screen-2xl mx-auto'>
          <SectionTail2 className='w-full absolute top-0 left-1/2  transform -translate-x-1/2' />
          <SectionTail className='hidden lg:block absolute z-10 top-1/2 left-1/2 md:left-auto transform -translate-y-1/2 -translate-x-1/2 md:-translate-x-0 more-sections-tail' />

          <div className='max-w-screen-xl mx-auto px-6 md:px-4 relative z-20'>
            <SectionHeader
              head={strapiShopperPage.moreSectionHead}
              lead={strapiShopperPage.moreSectionLead}
              maxWidth='max-w-3xl'
              headVariant='h2'
            />
            <div className='flex flex-wrap -mx-4'>

              {strapiShopperPage.moreSectionItems.map(({ id, headFirstLine, headSecondLine, lead, icon }) => {
                return (
                  <FeatureIconItem
                    key={id}
                    headFirstLine={headFirstLine}
                    headSecondLine={headSecondLine}
                    lead={lead}
                    icon={getImage(icon?.localFile)}
                  >
                  </FeatureIconItem>
                )
              })}
            </div>
          </div>
        </div>
      </section>

      <section className='md:pt-4 relative'>
        <QuoteTailTop className='absolute top-0 z-10 left-1/2 transform -translate-x-1/2 w-full lg:hidden' />
        <div className='max-w-screen-xl mx-auto px-6 md:px-4 relative z-50'>
          <Slider {...settings}>
            {strapiShopperPage.quotes.map(({ id, quote, name, company, image }) => {
              return (
                <div key={id}>
                  <QuoteSection
                    quote={quote}
                    name={name}
                    company={company}
                    image={getImage(image?.localFile)}
                  />
                </div>
              )
            })}
          </Slider>
          <QuoteTail className='hidden md:block text-primary-80 fill-current w-full md:width-auto absolute z-10 left-4 bottom-0 transform translate-y-1/2 quote-tail' />
        </div>
      </section>

      <CallToActionSection
        bg='bg-secondary'
        head={strapiShopperPage.bottomCTAHead}
        headSecondLine={strapiShopperPage.bottomCTAHeadSecondLine}
        lead={strapiShopperPage.bottomCTALead}
      >
        <EarlyAccessForm button='Get early access' placeholder='Your email' />
      </CallToActionSection>
    </Layout >
  )
}

export default IndexPage

export const query = graphql`
  query {
    strapiShopperPage {
      heroHead
      heroHeadSecondLine
      heroLead
      featuresSection {
        head
        headSecondLine
        id
        lead
      }
      moreSectionHead
      moreSectionLead
      moreSectionItems {
        id
        headFirstLine
        headSecondLine
        lead
        icon {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: NONE
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
      bottomCTAHead
      bottomCTAHeadSecondLine
      bottomCTALead
      quotes {
        id
        quote
        name
        company
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: NONE
                layout: CONSTRAINED,
                width: 1064,
                height: 1448
              )
            }
          }
        }
      }
    }
  }
`;