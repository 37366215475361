import React from 'react'
import PropTypes from 'prop-types'
import Heading from '@atoms/Heading'

const FeatureSection = ({ head, headSecondLine, lead, index, children }) => {

  return (
    <section className='py-4 relative z-20'>
      <div className='max-w-screen-2xl mx-auto px-6 md:px-4'>
        <div className={`flex flex-wrap items-center -mx-4 ${index % 2 === 0 ? 'md:flex-row-reverse' : ''}`}>
          <div className='w-full md:w-1/2 px-4'>
            <div className='lg:pl-24 lg:pr-20'>
              <Heading component='h3' variant='h2' classes='mb-4'>
                {head}
                {headSecondLine &&
                  <span className='block'>{headSecondLine}</span>
                }
              </Heading>
            </div>
            <div>
              <div className='lg:px-24'>
                <div className='prose md:prose-lg'>
                  {lead}
                </div>
              </div>
            </div>
          </div>
          <div className='w-full md:w-1/2 px-4'>
            <div className='p-4 md:p-0 mt-3 mb-4 md:mb-8 lg:mt-0 lg:mb-0'>
              <div className='w-full m-0 max-w-md mx-auto md:max-w-none lg:px-10'>
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

FeatureSection.propTypes = {
  head: PropTypes.string,
  lead: PropTypes.string
}

FeatureSection.defaultProps = {
  head: ``,
  lead: ``,
}

export default FeatureSection
