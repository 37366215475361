import React, { useState } from "react"
import PropTypes from "prop-types"
import Button from "@atoms/Button"
import SimulatorCompletePopUp from "@molecules/SimulatorCompletePopUp"
import axios from "axios"
import { useForm, Controller } from "react-hook-form"

const EarlyAccessForm = ({ button, placeholder }) => {
  const [showCompletedPopUp, setShowCompletedPopUp] = useState(false)
  const { control, handleSubmit, formState: { errors }, reset } = useForm({
    mode: 'onSubmit', defaultValues: {
      email: '',
    }
  })

  const handleSubmitForm = ({ email }) => {
    axios.post(`${process.env.GATSBY_API_URL}/shoppers`, { email: email })
      .then(() => setShowCompletedPopUp(true))
    reset('', {
      keepValues: false,
    })
  }

  return (
    <>
      <form
        id="early-access-form"
        className="w-full m-0 max-w-sm mx-auto md:max-w-none md:mx-0"
        style={{ minHeight: "74px" }}
        onSubmit={handleSubmit(handleSubmitForm)}
      >
        <div className="relative">
          <Controller
            name="email"
            control={control}
            rules={{
              required: "Please enter your email",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Please enter your email"
              }
            }}
            render={({ field: { onChange, value } }) => (
              <>
                <input
                  type='email'
                  placeholder={placeholder}
                  onChange={onChange}
                  value={value}
                  className={`border-white focus:border-white px-4 py-3 md:h-68px rounded-full border-4 bg-transparent w-full text-white outline-none focus:ring-0`}
                />
                <div className='text-micro text-error h-6 absolute top-full pl-4'>{errors?.email?.message}</div>
              </>
            )}
          />
          <div className="md:absolute md:top-0 md:right-0 md:h-full mt-4 md:mt-0">
            <Button variant="primary" classes="md:h-full rounded-full w-full md:w-auto">
              {button}
            </Button>
          </div>
        </div>
      </form>
      {showCompletedPopUp &&
        <SimulatorCompletePopUp head="Done!">
          <div className="prose text-secondary-120">
            <p>
              Thanks for joining Tenko.
              <br />
              We will keep you up to date!
            </p>
          </div>
          <div className="mt-8">
            <Button variant="primary" isWide handleClick={() => setShowCompletedPopUp(false)}>OK</Button>
          </div>
        </SimulatorCompletePopUp>
      }
    </>
  )
}

EarlyAccessForm.propTypes = {
  button: PropTypes.string,
  placeholder: PropTypes.string
}

EarlyAccessForm.defaultProps = {
  button: ``,
  placeholder: ``
}

export default EarlyAccessForm
